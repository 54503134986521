import { useStore } from "@nanostores/react";
import { Fragment, useEffect, useRef } from "react";

import { updateAdvertory } from "@vgno/adverts";
import { Teaser } from "@vgno/teasers/Teaser";
import { getReadtimeMinutes } from "@vgno/utils";
import { Video } from "@vgno/video";
import RelatedTags from "@vgno/widgets/RelatedTags";
import VideoCarousel from "@vgno/widgets/VideoCarousel";

import { NEWSROOM } from "../consts";
import { $teaserStore, fetchTeasers } from "../utils/fetchFront";

import type { Article } from "@vgno/article";
import type { Tag, Video as VideoType } from "vg";

interface Props {
  isIOS?: boolean;
  podcastEpisode: VideoType;
  relatedTags?: Tag[] | null;
  verticalVideos?: (VideoType & { url: string })[];
}

export const Feed = ({
  isIOS,
  podcastEpisode,
  relatedTags,
  verticalVideos,
}: Props) => {
  const sentinel = useRef<HTMLDivElement | null>(null);
  const { bundles, isFinished, isLoading } = useStore($teaserStore);

  // Intersection Observer for loading more teasers
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          fetchTeasers();
        }
      },
      {
        root: null,
        rootMargin: "100%",
        threshold: 0.1,
      },
    );

    const currentSentinel = sentinel.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [bundles.length]);

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');

    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }

    if (!isLoading) {
      updateAdvertory({}, { contextSelector: "#feed" });
    }
  }, [isLoading]);

  const widgets = {
    3: (
      <Video assetId={podcastEpisode.id} assetType={podcastEpisode.assetType} />
    ),
    5: verticalVideos && (
      <VideoCarousel
        className="widget p-m background-secondary shadow-small"
        format="vertical"
        isIOS={isIOS}
        title="Korte videoer"
        videos={verticalVideos}
      />
    ),
    10: relatedTags ? (
      <RelatedTags className="widget" newsroom={NEWSROOM} tags={relatedTags} />
    ) : null,
  };

  return (
    <section
      data-track-curate-context="frontpage"
      style={{ display: "contents" }}
    >
      {bundles.map((bundle, bundleIndex) => {
        if (bundle.length === 0) return undefined;

        return (
          <Fragment key={bundle[0].id}>
            {widgets[bundleIndex]}
            {bundle.map((teaser, teaserIndex) => {
              const teaserSize = getTeaserSize(
                teaser,
                bundleIndex,
                teaserIndex,
              );

              const video = teaser.components?.find(
                (component) => component.type === "video",
              );

              let duration: number | undefined;
              const isVgtvVideoTeaser =
                teaser.contentType === "video" &&
                video &&
                video.type === "video";

              if (isVgtvVideoTeaser) {
                duration = video.videoAsset.duration;
              }

              return (
                <track-element
                  data-track-primary-position={bundleIndex + 1}
                  data-track-bundle-position={teaserIndex + 1}
                  data-track-target-newsroom={teaser.newsroom}
                  data-track-id={`teaser:${teaser.id}`}
                  data-track-element-size={teaserSize}
                  data-track-element-type="Teaser"
                  data-track-target-type="Article"
                  data-track-click-intent="View"
                  data-track-image-url={
                    teaser.promotionContent?.imageAsset?.urls?.[0]?.url
                  }
                  data-track-impression
                  data-track-name={teaser.title.value}
                  key={teaser.id}
                >
                  <Teaser
                    alt={teaser.promotionContent?.alternateText?.value}
                    authors={teaser.authors}
                    contentType={teaser.contentType}
                    duration={duration}
                    fetchpriority={bundleIndex === 0 ? "high" : "low"}
                    headlineType="h2"
                    imageAsset={teaser.promotionContent?.imageAsset}
                    loading={bundleIndex >= 1 ? "lazy" : "eager"}
                    newsroom={teaser.newsroom}
                    paywall={teaser.restrictions === "subscription"}
                    readTimeMinutes={getReadtimeMinutes(teaser.wordCount)}
                    size={teaserSize}
                    timestamp={teaser.changes.published}
                    title={
                      teaser.customProperties.frontPageTitle ||
                      teaser.title.value
                    }
                    type={teaser.customProperties.presentation}
                    url={teaser.links.canonicalUrl}
                  />
                </track-element>
              );
            })}
          </Fragment>
        );
      })}
      {!isFinished && <div className="spinner" ref={sentinel} />}
    </section>
  );
};

function getTeaserSize(
  teaser: Article["teaser"],
  bundleIndex: number,
  teaserIndex: number,
) {
  return teaser.characteristics.hotness >= 60 ||
    (bundleIndex === 0 && teaserIndex === 0)
    ? "medium"
    : "small";
}
