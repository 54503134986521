import { jsx as a, jsxs as d } from "react/jsx-runtime";
import { FriendlyTimestamp as I } from "@vgno/core";
import { Play as K, Pullquote as Q } from "@vgno/icons";
import { mergeStyles as U, classnames as t, getSrcSet as P, getHotspot as W } from "@vgno/utils";
import { V as X, a as Y, f as Z, T as C } from "./chunks/VideoTeaser.js";
import './assets/Teaser.css';function ee(n) {
  const l = Math.ceil(n);
  if (l > 60) {
    const c = Math.floor(l / 60), i = l % 60;
    return `${c} t ${i} min lesetid`;
  }
  return `${l} min lesetid`;
}
const ae = "_article_9vanb_1", le = "_author_9vanb_5", ne = "_avatar_9vanb_16", te = "_content_9vanb_25", se = "_small_9vanb_35", ie = "_medium_9vanb_39", re = "_large_9vanb_43", oe = "_overlay_9vanb_44", ce = "_figure_9vanb_51", me = "_image_9vanb_55", ue = "_kicker_9vanb_66", de = "_label_9vanb_67", _e = "_link_9vanb_75", ve = "_opinion_9vanb_123", pe = "_metaData_9vanb_129", be = "_meta_9vanb_129", he = "_name_9vanb_151", ge = "_paywall_9vanb_157", fe = "_picture_9vanb_161", ye = "_duration_9vanb_187", Ne = "_play_9vanb_191", we = "_pullquote_9vanb_206", ke = "_timestamp_9vanb_215", Se = {
  article: ae,
  author: le,
  avatar: ne,
  content: te,
  small: se,
  medium: ie,
  large: re,
  overlay: oe,
  figure: ce,
  image: me,
  kicker: ue,
  label: de,
  link: _e,
  opinion: ve,
  metaData: pe,
  meta: be,
  name: he,
  paywall: ge,
  picture: fe,
  duration: ye,
  play: Ne,
  pullquote: we,
  timestamp: ke
}, xe = {
  large: "(min-width: 580px) 580px, 100vw",
  medium: "(min-width: 580px) 580px, 100vw",
  small: "300px"
}, De = {
  column: "Meninger",
  editorial: "Leder",
  "op-ed": "Meninger"
}, ze = {
  dinepenger: "Dine Penger",
  vg: "VG+"
}, Ae = ({ author: n, styles: l }) => {
  var m;
  const c = n.title, { value: i } = ((m = n.contacts) == null ? void 0 : m.find(
    ({ type: r }) => r === "title"
  )) || {
    value: "Journalist"
  };
  return /* @__PURE__ */ d("div", { className: t("label-small", "label-primary", l.author), children: [
    n.imageAsset && /* @__PURE__ */ a(
      "img",
      {
        alt: `Bilde av ${c}`,
        className: l.avatar,
        loading: "lazy",
        sizes: "48px",
        src: n.imageAsset.urls[0].url,
        srcSet: P(n.imageAsset)
      }
    ),
    /* @__PURE__ */ a("p", { className: l.name, children: c }),
    i && /* @__PURE__ */ a("p", { children: i })
  ] });
}, Ve = ({
  alt: n,
  authors: l,
  breakingNewsPrefix: c,
  className: i = "",
  contentType: m = "article",
  duration: r,
  fetchpriority: $,
  hasBreakingIndicator: j,
  headlineType: g = "h3",
  imageAsset: s,
  kicker: f,
  loading: y,
  newsroom: H = "vg",
  onClick: p,
  overlay: V = !1,
  paywall: O = !1,
  queryParams: B = {},
  readTimeMinutes: b,
  showTimestamp: E = !0,
  size: F = "medium",
  styles: G = {},
  timestamp: _,
  title: N,
  type: v = "news",
  url: J,
  videoPreviewURL: w = void 0,
  // if exists then we need to show a video teaser
  ...k
}) => {
  var z, A, M, q;
  const e = U(Se, G), L = Object.entries(B), h = new URL(J), S = l ? l[0] : void 0;
  for (const [R, T] of L)
    h.searchParams.set(R, T);
  const o = ["column", "editorial", "op-ed"].includes(v), u = v === "feature" ? "large" : F, x = u === "large" || o && u === "medium" || V, D = _ !== void 0 && Me(_);
  return w ? /* @__PURE__ */ a(
    X,
    {
      className: i,
      contentType: m,
      duration: r,
      headlineType: g,
      isOpinion: o,
      onClick: p,
      title: N,
      url: h,
      videoPreviewURL: w,
      ...k
    }
  ) : /* @__PURE__ */ a(
    "article",
    {
      ...x && {
        "data-color-scheme": "dark"
      },
      className: t(
        e.article,
        e[u],
        o && e.opinion,
        (o || x) && e.overlay,
        i
      ),
      ...k,
      children: /* @__PURE__ */ d(
        "a",
        {
          className: t("label-primary", e.link),
          "data-use-overlay-player": m === "video",
          href: h.toString(),
          onClick: p ? () => p : void 0,
          children: [
            ((A = (z = s == null ? void 0 : s.urls) == null ? void 0 : z[0]) == null ? void 0 : A.url) && /* @__PURE__ */ d("picture", { className: e.picture, children: [
              /* @__PURE__ */ a(
                "img",
                {
                  alt: n || "",
                  className: e.image,
                  fetchpriority: $,
                  height: (M = s.size) == null ? void 0 : M.height,
                  loading: y,
                  decoding: y === "eager" ? "async" : void 0,
                  sizes: xe[u],
                  src: s.urls[0].url,
                  srcSet: P(s),
                  style: {
                    objectPosition: W(s, "initial")
                  },
                  width: (q = s.size) == null ? void 0 : q.width
                }
              ),
              m === "video" && r !== void 0 && /* @__PURE__ */ d("div", { className: e.play, children: [
                /* @__PURE__ */ a(K, { "aria-hidden": !0 }),
                r > 0 ? /* @__PURE__ */ a(
                  "small",
                  {
                    "aria-label": `Video på ${Y(r)}`,
                    className: t(e.duration, "label-small"),
                    children: Z(r)
                  }
                ) : void 0
              ] })
            ] }),
            /* @__PURE__ */ d(
              "div",
              {
                className: t(e.content, "label-small", "label-primary"),
                children: [
                  o && /* @__PURE__ */ a(
                    Q,
                    {
                      "aria-label": "Meninger ikon",
                      className: e.pullquote
                    }
                  ),
                  o && (v === "column" && S ? /* @__PURE__ */ a(Ae, { author: S, styles: e }) : /* @__PURE__ */ a("p", { className: e.label, children: De[v] })),
                  f && /* @__PURE__ */ a(
                    "p",
                    {
                      className: t(
                        e.kicker,
                        `label-${u === "large" ? "primary" : "secondary"}`
                      ),
                      children: f
                    }
                  ),
                  /* @__PURE__ */ a(
                    C,
                    {
                      breakingNewsPrefix: c,
                      hasBreakingIndicator: j,
                      headlineType: g,
                      isOpinion: o,
                      size: u,
                      title: N
                    }
                  ),
                  /* @__PURE__ */ d("ul", { className: e.metaData, children: [
                    O && /* @__PURE__ */ a("li", { children: /* @__PURE__ */ a("span", { className: t(e.paywall, e.meta), children: ze[H] }) }),
                    _ && E && /* @__PURE__ */ a("li", { children: /* @__PURE__ */ a(
                      I,
                      {
                        className: t(e.timestamp, e.meta),
                        options: {
                          day: "numeric",
                          hour: D ? !1 : void 0,
                          minute: D ? !1 : void 0,
                          month: "short",
                          weekday: !1
                        },
                        timestamp: _
                      }
                    ) }),
                    b !== void 0 && b > 0 && /* @__PURE__ */ a("li", { children: /* @__PURE__ */ a("span", { className: t(e.meta, "label-small"), children: ee(b) }) })
                  ] })
                ]
              }
            )
          ]
        }
      )
    }
  );
};
function Me(n) {
  return (Date.now() - new Date(n).getTime()) / 36e5 > 3;
}
export {
  Ve as Teaser,
  xe as imageSizes
};
